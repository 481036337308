<template>
    <div>
      <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card class="text-center">
          <v-card-text class="pa-6">
            <v-icon
              color="primary"
              size="80"
              class="animate__animated animate__rotateIn"
            >
              mdi-information-outline
            </v-icon>
            <h2 class="text--primary mt-3">Refuser une demande de suppression</h2>
            <p class="mt-5 mb-5">
              Voulez-vous vraiment refuser cette demande ?
            </p>
            <v-btn
              text
              class="rounded-lg mr-2 text-none"
              large
              @click="notConfirmed()"
            >
              <v-icon left>mdi-close</v-icon> Annuler
            </v-btn>
            <v-btn
              :loading="loading"
              class="rounded-lg text-none"
              large
              color="primary"
              depressed
              @click="confirm()"
            >
              <v-icon left>mdi-check-circle-outline</v-icon> Confirmer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
      
      <script>
  import { HTTP } from "@/http-common";
  export default {
    data() {
      return {
        dialog: false,
        is_confirm: false,
        confirm_dialog: false,
        item: null,
        loading: false,
      };
    },
    methods: {
      open(item) {
        this.item = item;
        this.dialog = true;
      },
      confirm() {
        this.loading = true;
  
        HTTP.post("v1/users/disable_requests/refuse", {
                request_id: this.item.id
            })
          .then((res) => {
            this.loading = false;
            this.$successMessage = "La demande a été refusée";

            this.$emit("refresh");
            this.dialog = false;
          })
          .catch((e) => {
            this.loading = false;
            this.$errorMessage = "Une erreur s'est produite";
            console.log(e);
          });
      },
      notConfirmed() {
        this.dialog = false;
      },
    },
  };
  </script>